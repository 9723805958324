import { ReactElement, ReactNode, VFC } from "react";
import { Tooltip, TooltipProps, TooltipTitle } from "./Tooltip";

export interface ReasonTipReason {
  test: boolean;
  message: TooltipTitle;
}

export function evaluateReason(reasons: ReasonTipReason[]): [boolean, ReactNode] {
  const index = reasons.findIndex(({ test }) => test);
  if (index === -1) return [false, undefined];
  else return [true, reasons[index].message];
}

export type ReasonTipChildrenFn = (hasReason: boolean, message: ReactNode) => TooltipProps["children"];
export type ReasonTipChildren = TooltipProps["children"] | ReasonTipChildrenFn;

export type ReasonTipProps = Omit<TooltipProps, "children" | "title"> & {
  reasons: ReasonTipReason[];
  appendReasonToTitle?: boolean;
  title?: TooltipProps["title"];
  children?: ReasonTipChildren;
  childrenOverride?: ReasonTipChildren;
  noSpan?: boolean;
};

export const ReasonTip: VFC<ReasonTipProps> = ({
  reasons,
  children,
  childrenOverride,
  appendReasonToTitle,
  title,
  noSpan,
  ...rest
}) => {
  if (!children && !childrenOverride) throw new Error("Must provide either children or childrenOverride");

  const [hasReason, message] = evaluateReason(reasons);
  let tt: ReactNode;

  if (hasReason) tt = message;
  if (appendReasonToTitle && title && tt) tt = `${title} ${message}`;
  if (!tt) tt = title;

  children = childrenOverride || children;
  children = typeof children === "function" ? children(hasReason, message) : children;

  return (
    <Tooltip
      title={tt || false}
      disableHoverListener={!tt}
      disableTouchListener={!tt}
      disableFocusListener={!tt}
      enterDelay={0}
      disabled={!hasReason && !title}
      {...rest}
    >
      {noSpan ? (children as ReactElement) : <span>{children}</span>}
    </Tooltip>
  );
};
